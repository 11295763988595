import React, { useEffect } from "react"
import styled from "styled-components"

import Layout from "../../components/layout-canada"
import SEO from "../../components/seo"

const P = styled.p`
  font-family: "Benton Sans";
  text-align: justify;
`

const Container = styled.div`
  margin: 0 auto;
  text-align: center;
`

const RsvpPage = () => {
  useEffect(() => {
    document
      .getElementById("google-form")
      .addEventListener("load", () => window.scrollTo(0, 0))
  }, [])

  return (
    <Layout>
      <SEO title="RSVP | Sara & Tim" />
      <Container>
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSfXlAy1uxE2Ih3_3h2ivxE1yO2li08P7sM6Ey9O_xkwRc8eMw/viewform?embedded=true"
          width="100%"
          max-width="700px"
          height="520"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
          id="google-form"
        >
          Loading…
        </iframe>
      </Container>
    </Layout>
  )
}

export default RsvpPage
